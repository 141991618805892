<template>
    <div class="tiktok_rank_box">
        <div class="header">
            <div v-for="(item, index) in allRankList" class="header_item"
                :class="activeTypeIndex == index ? 'active_header_item' : ''" @click="changeTypeIndex(index)">
                <span class="">{{ item.name }}</span>
            </div>
        </div>
        <div class="rank_box">
            <RankModule v-if="activeTypeIndex != 0" :rankObj="rankObj"></RankModule>
            <RankHome v-else @changeTypeIndex="changeTypeIndex"></RankHome>
        </div>

    </div>
</template>
<script>
import RankModule from "../../../components/mediumModule/RankModule.vue"
import RankHome from "../../../components/mediumModule/RankHome.vue"
export default {
    components: {
        RankModule,
        RankHome
    },
    data() {
        return {
            allRankList: [
                {
                    name: "全部",
                    id: 0
                },
                {
                    name: "音乐",
                    id: 1,
                    rankName: [
                        {
                            name: "热歌榜",
                            type: 1
                        },
                        {
                            name: "飙升榜",
                            type: 2
                        },
                        {
                            name: "原创榜",
                            type: 3
                        },
                    ],
                },
                {
                    name: "美食",
                    id: 2,
                    rankName: [
                        {
                            name: "美食总榜",
                            type: 1
                        },
                        {
                            name: "新势力榜",
                            type: 2
                        },
                        {
                            name: "美食教程",
                            type: 3
                        },
                        {
                            name: "美食探店",
                            type: 4
                        },
                    ],
                },
                {
                    name: "剧情",
                    id: 3,
                    rankName: [
                        {
                            name: "剧情总榜",
                            type: 5
                        },
                    ],
                },
                {
                    name: "直播",
                    id: 4,
                    rankName: [
                        {
                            name: "直播",
                            type: 1
                        },
                    ],
                },
                {
                    name: "搞笑",
                    id: 5,
                    rankName: [
                        {
                            name: "搞笑总榜",
                            type: 6
                        },
                        {
                            name: "新势力榜",
                            type: 7
                        },
                    ],
                },
                {
                    name: "二次元",
                    id: 6,
                    rankName: [
                        {
                            name: "二次元总榜",
                            type: 8
                        },
                        {
                            name: "轻漫",
                            type: 9
                        },
                        {
                            name: "出镜拍摄",
                            type: 10
                        },
                        {
                            name: "绘画",
                            type: 11
                        },
                        {
                            name: "声控",
                            type: 12
                        }, {
                            name: "脑洞",
                            type: 13
                        }, {
                            name: "新势力榜",
                            type: 14
                        },
                    ],
                },
                {
                    name: "游戏",
                    id: 7,
                    rankName: [
                        {
                            name: "单机主机",
                            type: 15
                        },
                        {
                            name: "游戏资讯",
                            type: 16
                        },

                    ],
                },
                {
                    name: "影视综艺",
                    id: 8,
                    rankName: [
                        {
                            name: "电影",
                            type: 1
                        },
                        {
                            name: "电视剧",
                            type: 2
                        },
                        {
                            name: "综艺榜",
                            type: 3
                        },
                    ],
                },
                {
                    name: "汽车",
                    id: 9,
                    rankName: [
                        {
                            name: "汽车总榜",
                            type: 17
                        },
                        {
                            name: "评车",
                            type: 18
                        },
                        {
                            name: "玩车",
                            type: 19
                        },
                        {
                            name: "用车",
                            type: 20
                        },
                        {
                            name: "驾考",
                            type: 21
                        },
                    ],
                },
                {
                    name: "旅游",
                    id: 10,
                    rankName: [
                        {
                            name: "旅游总榜",
                            type: 22
                        },
                        {
                            name: "新势力榜",
                            type: 23
                        },

                    ],
                },
                {
                    name: "体育",
                    id: 11,
                    rankName: [
                        {
                            name: "体育总榜",
                            type: 24
                        },
                        {
                            name: "篮球",
                            type: 25
                        },
                        {
                            name: "足球",
                            type: 26
                        },
                        {
                            name: "综合体育",
                            type: 27
                        },
                        {
                            name: "运动健身",
                            type: 28
                        },
                        {
                            name: "户外运动",
                            type: 29
                        },
                        {
                            name: "台球",
                            type: 30
                        },
                        {
                            name: "运动文化",
                            type: 31
                        },
                    ],
                },
                {
                    name: "娱乐明星",
                    id: 12,
                    rankName: [
                        {
                            name: "娱乐明星",
                            type: 32
                        },

                    ],
                },
            ],
            activeTypeIndex: 0,
            rankObj: {}

        }
    },
    mounted() {
        if (localStorage.getItem("activeTypeIndex")) {
            this.activeTypeIndex = localStorage.getItem("activeTypeIndex")
            this.rankObj = this.allRankList[this.activeTypeIndex]
        } else {
            localStorage.setItem("activeTypeIndex", 0)
            this.rankObj = this.allRankList[0]
        }

    },
    methods: {
        changeTypeIndex(index) {
            this.activeTypeIndex = index
            this.rankObj = this.allRankList[index]
            localStorage.setItem("activeTypeIndex", index)
        }
    }
}
</script>
<style lang="scss" scoped> .tiktok_rank_box {
     .header {
         width: 100%;
         height: 50px;
         background: #FFFFFF;
         border-radius: 4px 4px 4px 4px;
         display: flex;
         align-items: center;
         padding: 10px 20px;
         box-sizing: border-box;

         .header_item {
             padding: 0 16px;
             cursor: pointer;

             &:nth-of-type(1) {
                 border-right: 1px solid #bdbcbc;
             }
         }

         .active_header_item {
             color: #685EF5;
         }

     }

     .rank_box {
         width: 100%;
         margin-top: 20px;
         //  background: #FFFFFF;
         border-radius: 4px 4px 4px 4px;
         min-height: 900px;
     }
 }
</style>