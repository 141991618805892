<template>
    <div class="rank_home_box">
        <div class="rank_item" v-for="(item, index) in allRankList">
            <div class="header" @click="goMore(index)">
                <div class="left">
                    <h2>{{ item.name }}</h2>
                    <div class="crad"><span v-for="itemChild in item.label">{{ itemChild }}</span></div>
                </div>
                <div class="more">更多 ></div>
            </div>
            <div class="list_box">
                <template v-for="(itemC, index) in item.data">
                    <div class="list_item food"
                        v-if="index < 5 && (item.name == '美食' || item.name == '剧情' || item.name == '娱乐明星' || item.name == '搞笑' || item.name == '二次元' || item.name == '游戏' || item.name == '二次元' || item.name == '汽车' || item.name == '旅游' || item.name == '体育')">
                        <span
                            :style="{ color: itemC.rank == 1 ? '#db5336' : itemC.rank == 2 ? '#dd713e' : itemC.rank == 3 ? '#edb013' : '#16182366' }">{{
                                itemC.rank }}.</span>
                        <div class="ava">
                            <img :src="itemC.avatar" alt="">
                        </div>
                        <div class="nickname">
                            {{ itemC.nickname }}
                        </div>
                        <div class="number">
                            {{ itemC.effect_value }}影响力
                        </div>
                    </div>
                    <div class="list_item music" v-if="index < 5 && (item.name == '音乐')">
                        <span
                            :style="{ color: itemC.rank == 1 ? '#db5336' : itemC.rank == 2 ? '#dd713e' : itemC.rank == 3 ? '#edb013' : '#16182366' }">{{
                                itemC.rank }}.</span>

                        <div class="ava" v-if="item.name == '音乐'">
                            <img :src="itemC.cover" alt="">
                        </div>
                        <div class="nickname">
                            {{ itemC.title }}
                        </div>
                        <div class="number">
                            {{ itemC.use_count }}使用
                        </div>

                    </div>
                    <div class="list_item ent" v-if="index < 5 && (item.name == '影视综艺')">
                        <span
                            :style="{ color: itemC.rank == 1 ? '#db5336' : itemC.rank == 2 ? '#dd713e' : itemC.rank == 3 ? '#edb013' : '#16182366' }">{{
                                itemC.rank }}.</span>
                        <div class="ava">
                            <img :src="itemC.poster" alt="">
                        </div>
                        <div class="nickname">
                            {{ itemC.name }}
                        </div>
                        <div class="number">
                            <img src="../../assets/img/image/hot.svg" alt=""> {{ itemC.hot }}
                        </div>

                    </div>


                </template>

            </div>

        </div>


    </div>
</template>
<script>
export default {
    props: ["rankObj"],
    data() {
        return {
            activeRankIndex: 0,
            allRankList: []
        }
    },
    mounted() {
        this.getALLRankList()
    },
    methods: {
        changeRankIndex(item, index) {
            this.activeRankIndex = index

        },
        getALLRankList() {
            // billboardDataIndex
            this.$httpStudent.axiosGet(this.$api.billboardDataIndex, res => {
                if (res.code == 200) {

                    let arr = [{
                        name: "音乐",
                        label: ["热歌榜", "飙升榜...",],
                        data: res.data.music
                    },

                    {
                        name: "美食",
                        label: ["美食总榜...",],
                        data: res.data.food
                    },
                    {
                        name: "剧情",
                        label: ["剧情总榜"],
                        data: res.data.drama
                    },
                    {
                        name: "搞笑",
                        label: ["搞笑总榜", "新势力榜"],
                        data: res.data.amusement
                    },
                    {
                        name: "二次元",
                        label: ["二次元总榜...",],
                        data: res.data.cospa
                    },
                    {
                        name: "游戏",
                        label: ["单机主机", "游戏资讯",],
                        data: res.data.game
                    },
                    {
                        name: "影视综艺",
                        label: ["电影", "电视剧...",],
                        data: res.data.ent
                    },
                    {
                        name: "汽车",
                        label: ["汽车总榜", "评车...",],
                        data: res.data.car
                    },
                    {
                        name: "旅游",
                        label: ["旅游总榜", "新势力榜",],
                        data: res.data.travel
                    },
                    {
                        name: "体育",
                        label: ["体育总榜", "篮球...",],
                        data: res.data.sport
                    },
                    {
                        name: "娱乐明星",
                        label: ["娱乐明星"],
                        data: res.data.stars
                    },
                    ]
                    this.allRankList = arr
                }

            }, err => {

            })
        },
        goMore(index){
            this.$emit("changeTypeIndex",index+1)
        },
        
    }
}
</script>
<style lang="scss" scoped>
.rank_home_box {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    // background: #f2f2f2;

    .rank_item {

        height: 386px;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        max-width: 425px;

        .header {
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-bottom: 12px;
            cursor: pointer;
            justify-content: space-between;

            .left {
                flex: 1;
                width: 0;
                display: flex;
                align-items: flex-end;

                h2 {

                    padding: 0px;
                    margin: 0;
                    font-size: 22px;
                    margin: 0;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                }


                .crad {
                    display: flex;
                    padding: 0 10px;
                    cursor: pointer;
                    max-width: 145px;
                    align-items: center;
                    width: 100%;
                    color: rgba(22, 24, 35, 0.5);
                    overflow: hidden;

                    span {
                        margin-left: 10px;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                    }
                }
            }


            .more {
                padding: 0 10px;
            }
        }

        .list_box {
            display: flex;
            flex-direction: column;

            .list_item {
                width: 100%;
                display: flex;
                align-items: center;
                height: 62px;
                padding: 10px 0;
                box-sizing: border-box;

                .ava {
                    margin-left: 10px;
                }

                .nickname {
                    width: 190px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.5;
                    max-height: 3em;
                }
            }

            .food {
                .ava {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    border-radius: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }

            }

            .music {

                .ava {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    border-radius: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                    }
                }

            }

            .ent {
                .ava {
                    width: 32px;
                    height: 42px;
                    margin-right: 8px;
                    border-radius: 2px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                    }
                }

            }
        }
    }

}



</style>