<template>
    <div class="rank_content">
        <div class="header">
            <div class="rank_header">
                <div class="rank_item" v-for="(item, index) in rankObj.rankName"
                    :class="activeRankIndex == index ? 'active_rank_item' : ''" @click="changeRankIndex(item, index)">
                    {{ item.name }}
                </div>

            </div>
            <div class="select_time">
                <div class="time-box" v-if="rankObj.id != 4">
                    <div class="time-label">生成时段</div>
                    <div class="day-type">
                        <el-radio-group class="radio-group" v-model="params.data_type" size="small" @change="getListByTime">
                            <!-- <el-radio-button label="hour">小时榜</el-radio-button> -->
                            <el-radio-button label="day">日榜</el-radio-button>
                            <el-radio-button label="week">周榜</el-radio-button>
                            <el-radio-button label="month">月榜</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="time-filter-box">
                        <el-select class="list-type-box" size="mini" v-model="params.date" placeholder="请选择"
                            @change="getAllClass()">
                            <el-option v-for="item in currentListType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="time-box" v-else>
                    <div class="time-label">生成时段</div>
                    <div class="time-filter-box">
                        <el-select class="list-type-box" size="mini" v-model="params.date" placeholder="请选择"
                            @change="getAllClass()">
                            <el-option v-for="item in currentTodayListType" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="time-label">小时榜</div>
                    <div class="time-filter-box">
                        <el-select class="list-type-box" size="mini" v-model="params.time" placeholder="请选择"
                            @change="getAllClass()">
                            <el-option v-for="item in hours" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

        </div>
        <div class="medio-contain">
            <el-scrollbar style="height: 100%;">
                <div class="medio-list" v-if="itemList.length > 0">
                    <el-table v-if="rankObj.id == 1" :key="`1+${this.params.type}`" :data="itemList" style="width: 100%;"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', }"
                        :cell-style="{ fontSize: '14px', color: ' #252632' }">
                        <el-table-column label="排名" width="100" align="center">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.rank }}.
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="封面">
                            <template slot-scope="scope">
                                <img :src="scope.row.cover" alt="">
                            </template>
                        </el-table-column>
                        <el-table-column label="歌曲/时长">
                            <template slot-scope="scope">
                                <div @click="jumpToVideo(scope.row.share_url)" style="cursor: pointer;">
                                    <p>{{ scope.row.title }}</p>
                                    <p>{{ scope.row.duration }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="歌手">
                            <template slot-scope="scope">
                                {{ scope.row.author }}
                            </template>
                        </el-table-column>
                        <el-table-column label="使用量" width="100" align="left">
                            <template slot-scope="scope">
                                {{ scope.row.use_count }}
                            </template>
                        </el-table-column>

                    </el-table>
                    <el-table v-else-if="rankObj.id == 4" :key="`4+${this.params.type}`" :data="itemList"
                        style="width: 100%;"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', }"
                        :cell-style="{ fontSize: '14px', color: ' #252632' }">
                        <el-table-column label="排名" width="100" align="center">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.rank }}.
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="封面" width="200">
                            <template slot-scope="scope">
                                <img style="width: 60px;height: 60px;" :src="scope.row.cover" alt="">
                            </template>
                        </el-table-column>
                        <el-table-column label="直播间标题/用户名">
                            <template slot-scope="scope">
                                <div style="cursor: pointer;">
                                    <p>{{ scope.row.title }} <span>
                                            <el-popover placement="top" trigger="hover">
                                                <div
                                                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                                                    <img :src="scope.row.url" alt="">
                                                </div>
                                                <i class="iconfont" slot="reference"
                                                    style="color: #1c1f2399;;font-size: 18px; cursor: pointer;">&#xeaf1;</i>
                                            </el-popover>
                                        </span>
                                    </p>
                                    <p style="color: #1c1f2399;">{{ scope.row.nickname }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="热度指数" width="180">
                            <template slot-scope="scope">
                                <img src="../../assets/img/image/hot.svg" alt=""> {{ scope.row.hot_value }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table v-else-if="rankObj.id == 8" :key="`8+${this.params.type}`" :data="itemList"
                        style="width: 100%;"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', }"
                        :cell-style="{ fontSize: '14px', color: ' #252632' }">
                        <el-table-column label="排名" width="100" align="center">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.rank }}.
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="activeRankIndex == 0 ? '电影' : activeRankIndex == 1 ? '电视剧' : '综艺榜'">
                            <template slot-scope="scope">
                                <div class="ent_name">
                                    <img :src="scope.row.poster" alt="">
                                    <div class="ent_info">
                                        <p>{{ scope.row.name }}</p>
                                        <p class="areas">{{ scope.row.areas }} / {{ scope.row.tags }} / {{
                                            scope.row.directors
                                        }} / {{ scope.row.actors }}</p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="账号影响力" width="200">
                            <template slot-scope="scope">
                                {{ scope.row.influence_hot }}
                            </template>
                        </el-table-column>
                        <el-table-column label="话题热度值" width="200">
                            <template slot-scope="scope">
                                {{ scope.row.topic_hot }}
                            </template>
                        </el-table-column>
                        <el-table-column label="搜索指数" width="200">
                            <template slot-scope="scope">
                                {{ scope.row.search_hot }}
                            </template>
                        </el-table-column>
                        <el-table-column label="视频讨论度" width="200">
                            <template slot-scope="scope">
                                {{ scope.row.discussion_hot }}
                            </template>
                        </el-table-column>
                        <el-table-column label="热度值" width="120">
                            <template slot-scope="scope">
                                {{ scope.row.hot }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table v-else-if="rankObj.id == 12" :key="`12+${this.params.type}`" :data="itemList"
                        style="width: 100%;"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', 'padding-left':'27px'}"
                        :cell-style="{ fontSize: '14px', color: ' #252632','padding-left':'27px' }">
                        <el-table-column label="排名">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.rank }}.
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="头像/用户名">
                            <template slot-scope="scope">
                                <div style="display: flex;align-items: center;">
                                    <img style="width: 40px;height: 40px;border-radius: 100%;margin-right: 10px;"
                                        :src="scope.row.avatar" alt="">
                                    {{ scope.row.nickname }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="影响力指数" width="140">
                            <template slot-scope="scope">
                                {{ scope.row.effect_value }}
                            </template>
                        </el-table-column>

                    </el-table>

                    <el-table v-else ref="performanceTable" row-key="id" :expand-row-keys="expandsRows"
                        :key="this.params.type" @expand-change="expandChange" @row-click="clickRowHandle" :data="itemList"
                        style="width: 100%;"
                        :header-cell-style="{ fontWeight: '400', color: 'rgba(37,38,50,0.6)', background: ' #F2F2F4', }"
                        :cell-style="{ fontSize: '14px', color: ' #252632' }" :row-class-name="getRowClass">
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <div class="show_video">
                                    <div class="PreVideo">
                                        <svg width="14" height="46" viewBox="0 0 14 46" fill="none"
                                            class="slick-arrow slick-prev" elementtiming="element-timing"
                                            @click="switchDisplayVideo(props.row, 'prev')" v-if="props.row.isPreVideo">
                                            <path d="M13 1L1 23l12 22" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                elementtiming="element-timing"></path>
                                        </svg>
                                    </div>
                                    <div class="video_content">
                                        <div class="content" :style="{
                                            transform: `translateX(${props.row.translateXValue}px)`
                                        }">
                                            <div v-for=" (videoItem, index) in props.row.videos"
                                                @click="jumpToVideo(videoItem.share_url)" class="video_item">
                                                <div class="image">
                                                    <img :src="videoItem.item_cover" alt="" @error="handleError">
                                                    <!-- <img src="" alt=""> -->
                                                </div>
                                                <div class="video_title">{{ videoItem.title }}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="NextVideo">
                                        <svg width="14" height="46" viewBox="0 0 14 46" fill="none"
                                            class="slick-arrow slick-next" elementtiming="element-timing"
                                            @click="switchDisplayVideo(props.row, 'next')" v-if="props.row.isNextVideo">
                                            <path d="M1 1l12 22L1 45" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                elementtiming="element-timing">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="排名" width="100" align="center">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.rank }}.
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="" width="80" align="left">
                            <template slot-scope="scope">
                                <span v-if="scope.row.rank_change > 0" style="color: #FE2C55;">
                                    <i class="iconfont">&#xe670;</i>{{ scope.row.rank_change }}</span>
                                <span v-if="scope.row.rank_change < 0" style="color:  #70C900;">
                                    <i class="iconfont">&#xf0e4;</i>{{ -scope.row.rank_change }}</span>
                                <span v-if="scope.row.rank_change == 0">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="头像/用户名">
                            <template slot-scope="scope">
                                <div style="display: flex;align-items: center;">
                                    <img style="width: 40px;height: 40px;border-radius: 100%;margin-right: 10px;"
                                        :src="scope.row.avatar" alt="">
                                    {{ scope.row.nickname }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="粉丝数">
                            <template slot-scope="scope">
                                {{ scope.row.follower_count }}
                            </template>
                        </el-table-column>
                        <el-table-column label="近一月在榜次数">
                            <template slot-scope="scope">
                                {{ scope.row.onbillbaord_times }}
                            </template>
                        </el-table-column>
                        <el-table-column label="影响力指数" width="150" align="left">
                            <template slot-scope="scope">
                                {{ scope.row.effect_value }}
                            </template>
                        </el-table-column>

                    </el-table>
                </div>

                <div class="medio-load">
                    <!-- <span v-if="!loadMore" style="cursor: auto;">没有更多数据</span>
                    <span v-else @click="loadMoreData">点击加载更多</span> -->
                    <span v-if="itemList.length == 0">暂无数据</span>
                </div>
            </el-scrollbar>
        </div>
        <canvas style="display: none;" id="qrcodeCanvas" width="300" height="400"></canvas>
    </div>
</template>
<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { newAccountGetVideoCategory } from "@/utils/apis";
import { HOTVIDEO } from "@/utils/staticData";
import { getFormatDate } from "@/utils/formatDate";
let _this;
export default {
    props: ["rankObj"],
    directives: {
        "el-table-infinite-scroll": elTableInfiniteScroll,
    },
    data() {
        return {
            activeRankIndex: 0,
            data_type: "day",
            tag: "全部", //分类名称
            typeList: [], //分类列表
            itemList: [], //道具列表
            //分页
            adminPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
            params: {
                data_type: "day",
                type: "1",
                date: "",
                page: 0,
                limit: 50,
                time: ""
            },
            timer: -1,
            flag: true,
            showScrollLoading: false,
            timeFilterOptions: HOTVIDEO.timeFilterOptions,
            loading: true,
            loadMore: true, // 是否加载更多数据
            expandsRows: [],
            defaultImage: require("../../assets/img/image/noimage.png"),
            hours: [
                {
                    lable: "10:00",
                    value: "10:00"
                }
            ]//小时
        }
    },
    mounted() {
        _this = this;
        this.params.type = this.rankObj.rankName[0].type || this.rankObj.id

        this.initDate()

        this.getAllClass();
    },
    computed: {
        /**滚动提示语 */
        scrollHint() {
            return "加载中...";
        },
        /**当前的榜单 */
        currentListType() {
            let { data_type } = this.params;
            return this.timeFilterOptions[data_type];
        },
        currentTodayListType() {
            let date = new Date();
            // date.setMonth(date.getMonth() - 1);
            let list = [getFormatDate(date).split(" ")[0]];
            for (let i = 0; i < 29; i++) {
                date.setDate(date.getDate() - 1);
                list.push(getFormatDate(date).split(" ")[0]);
            }
            return list.map((item, index) => {
                return {
                    value: item,
                    label: item,
                };
            });
        },
    },
    filters: {
        /** 判断选中的分类tag */
        categoryStyle(item, nowTag) {
            return {
                current: item == nowTag || (item == "全部" && nowTag == ""),
            };
        },
    },
    watch: {
        "params.data_type": {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    if (this.params.data_type === "month") this.params.date = this.currentListType[0].label;
                    else {
                        if (this.rankObj.id != 4) {
                            this.params.date = this.currentListType[0].value;
                        } else {
                            this.params.date = this.currentTodayListType[0].value;
                        }
                    }
                }

            },
            immediate: true,
        },
        "rankObj": {
            handler(newVal, oldVal) {
                // console.log(newVal.id, oldVal.id, "newVal");

                if (newVal.id != oldVal.id) {
                    this.params.type = newVal.rankName[0].type
                    this.params.data_type="day"
                    this.flag = true
                    this.showScrollLoading = false
                    this.loading = true
                    this.loadMore = true
                    this.initDate()
                    this.getAllClass();
                    this.activeRankIndex = 0
                    this.expandsRows = []
                   
                }
            },
            deep: true,

        },
        "params.date": {
            handler(newval, oldVal) {
                if (this.rankObj.id == 4) {
                    this.params.date = newval;
                    this.getHours()
                }
            }
        }

    },
    methods: {
        initDate() {
            if (this.rankObj.id != 4) {
                const currentDate = this.currentListType;
                this.params.date = currentDate[0].value;
            } else {
                const currentDate = this.currentTodayListType;
                this.params.date = currentDate[0].value;
            }
            this.getHours()
        },
        getHours() {
            const dateArr = this.params.date.split("-"); // year month day
            const currentDate = new Date();
            let hour = 24;
            this.hours = [];
            if (currentDate.getDate() == dateArr[2] && currentDate.getMonth() + 1 == dateArr[1]) {
                hour = currentDate.getHours();
            }
            // console.log(this.hours, "this.hours");


            for (let i = 0; i <= hour; i++) {
                let addHour = "";
                addHour = i < 10 ? `0${i}` : i.toString();
                // console.log(addHour, "addHour");

                let obj = {
                    label: addHour + ":00",
                    value: addHour + ":00"
                }
                // console.log(obj);

                this.hours.push(obj);
            }
            let currentTime = this.hours[this.hours.length - 1]
            this.params.time = currentTime.value;
        },
        changeRankIndex(item, index) {
            this.activeRankIndex = index
            this.params.type = item.type
            this.flag = true
            this.showScrollLoading = false
            this.loading = true
            this.loadMore = true
            this.expandsRows = []
            this.getAllClass();

        },
        toggleDayType(e) {
            this.params.date = e;
        },
        getTaskType() {
            newAccountGetVideoCategory().then((res) => {
                if (res.code === 200) {
                    this.typeList = res.data;
                } else {
                    this.$message.warning(res.message);
                }
            });
        },
        chooseTag(item) {
            this.adminPages.currentPageNum = 1;
            this.params.star_category = item == "全部" ? "" : item;
            this.getAllClass();
        },
        getListByTime() {
            this.getAllClass();
        },
        // 格式化params日期
        formatWeekDate(date) {
            if (this.params.data_type !== "week") return date;

            let weekDate = "";
            const dateArr = date.split("-");
            dateArr.forEach((item, index) => {
                const formatDateStr = `${item.slice(0, 4)}-${item.slice(4, 6)}-${item.slice(6, 8)}`;
                weekDate += formatDateStr;

                if (index < dateArr.length - 1) {
                    weekDate += ",";
                }
            });
            return weekDate;
        },
        // 获取全部数据列表
        getAllClass(isAppend = false) {
            if (this.flag) {
                this.flag = false;
                // params处理
                this.params.page = isAppend ? this.params.page + 1 : 1;
                const requestParams = JSON.parse(JSON.stringify(this.params));
                requestParams.date = this.formatWeekDate(this.params.date);
                let url = ""
                if (this.rankObj.id == 1) {
                    url = this.$api.billboardMusic
                } else if (this.rankObj.id == 4) {
                    url = this.$api.billboardLive
                } else if (this.rankObj.id == 8) {
                    url = this.$api.billboardEnt
                } else {
                    url = this.$api.billboardData
                }
                this.$httpStudent.axiosGetBy(url, requestParams, (res) => {
                    if (res.code === 200) {
                        if (this.rankObj.id != 1 || this.rankObj.id != 4 || this.rankObj.id != 8 || this.rankObj.id != 12) {
                            res.data.list.forEach((item) => {
                                const obj = {
                                    id: item.id,
                                    page: 1,
                                    limit: 10,
                                    type: this.params.type
                                };

                                item.isPreVideo = false;
                                item.isNextVideo = true;
                                item.videoParams = obj;
                            });
                        }
                        this.itemList = isAppend ? this.itemList.concat(res.data.list) : res.data.list;
                        if (res.data.total === 0 || this.itemList.length >= res.data.total) this.loadMore = false;
                        else this.loadMore = true;
                        if (this.rankObj.id != 1 && this.rankObj.id != 4 && this.rankObj.id != 8 && this.rankObj.id != 12 && this.itemList.length > 0 && this.itemList[0]?.video_count > 0) {
                            this.$nextTick(() => {
                                this.$refs.performanceTable.toggleRowExpansion(this.itemList[0], true);
                                this.expandsRows.push(this.itemList[0].id)
                            })
                        }
                        // 给直播数据添加二维码
                        if (this.rankObj.id == 4 && this.itemList.length > 0) {
                            this.itemList.forEach((item, index) => {
                                let url = this.getTextCode(item.link)
                                // console.log(url);
                                item.url = url

                            })
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000,
                        });
                    }
                    this.flag = true;
                });
            }

        },

        // 格式化数量
        formatCount(value) {
            const count = parseFloat(value);
            if (isNaN(count)) return 0;

            if (count < 10000) return count.toString();
            else return (count / 10000).toFixed(1) + "万";
        },

        // 加载更多数据
        loadMoreData() {
            this.getAllClass(true);
        },

        //跳到详情页
        changeToDetail(row) {
            this.$router.push({
                path: "/student/hot/videoDetail",
                query: {
                    aweme_id: row.aweme_id,
                    author_id: row.author_id,
                },
            });
        },
        //跳到粉丝详情
        jumpToFansData(row) {
            this.$router.push({
                path: "/student/hot/videoDetail",
                query: {
                    aweme_id: row.aweme_id,
                    author_id: row.author_id,
                },
            });
        },
        //打开视频
        jumpToVideo(url) {
            window.open(url);
        },
        expandChange(row, expandedRows) {
            if (row.video_count == 0) {
                this.$refs.performanceTable.toggleRowExpansion(row, false);
                return
            }

            const changeHotspot = this.itemList.find((item) => {
                return item.id === row.id;
            });
            if (changeHotspot.videos && changeHotspot.videos.length > 0) return;
            this.getAssociatedVideo(changeHotspot)
        },
        // 获取到关联视频
        getAssociatedVideo(hotspot, type = "none") {

            let params = {
                type: hotspot.videoParams.type,
                id: hotspot.videoParams.id
            }
            this.$httpStudent.axiosGetBy(this.$api.billboardRelatedVideo, params, (res) => {
                if (res.code === 200) {
                    this.$set(hotspot, "videoTotal", res.data.total); // 视频总数量
                    this.$set(hotspot, "videos", res.data.list); // 视频列表
                    this.switchDisplayVideo(hotspot, "none")

                }
            });


        },
        clickRowHandle(row, column, event) {
            if (row.video_count == 0) {
                this.$refs.performanceTable.toggleRowExpansion(row, false);
                return
            }
            if (this.expandsRows.includes(row.id)) {
                this.expandsRows = this.expandsRows.filter(val => val != row.id)
            } else {
                const changeHotspot = this.itemList.find((item) => {
                    return item.id === row.id;
                });
                this.expandsRows.push(row.id)
                if (changeHotspot.videos && changeHotspot.videos.length > 0) return;
                this.getAssociatedVideo(changeHotspot)

            }

        },
        getRowClass(row, rowIndex) {
            if (row.row.video_count == 0) {  //判断当前行是否有子数据
                return 'row-expand-cover'
            } else {
                return "hhh"
            }
        },
        switchDisplayVideo(hotspot, type) {
            if (type === "prev") this.$set(hotspot.videoParams, "page", hotspot.videoParams.page - 1);
            else if (type === "next") this.$set(hotspot.videoParams, "page", hotspot.videoParams.page + 1);
            if (hotspot.videoTotal === 0 || hotspot.videoTotal <= 10) {
                this.$set(hotspot, "isPreVideo", false);
                this.$set(hotspot, "isNextVideo", false);
                let value = 0
                this.$set(hotspot, "translateXValue", value);
            } else if (hotspot.videoParams.page == 1) {
                this.$set(hotspot, "isPreVideo", false);
                this.$set(hotspot, "isNextVideo", true);
                // this.$set(hotspot, "visibleContents", hotspot.videos.slice(0, 10))
                let value = 0
                this.$set(hotspot, "translateXValue", value);
            } else if (hotspot.videoParams.page * hotspot.videoParams.limit >= hotspot.videoTotal) {
                this.$set(hotspot, "isPreVideo", true);
                this.$set(hotspot, "isNextVideo", false);
                // let arr = hotspot.videos.slice(hotspot.videoTotal - 10, hotspot.videoTotal)
                // this.$set(hotspot, "visibleContents", arr)
                let value = -150 * (hotspot.videoTotal - 10)
                this.$set(hotspot, "translateXValue", value);
            } else {
                let value = -150 * ((hotspot.videoParams.page - 1) * hotspot.videoParams.limit)
                this.$set(hotspot, "translateXValue", value);

                this.$set(hotspot, "isPreVideo", true);
                this.$set(hotspot, "isNextVideo", true);
                // let arr = hotspot.videos.slice((hotspot.videoParams.page - 1) * hotspot.videoParams.limit, (hotspot.videoParams.page) * hotspot.videoParams.limit)
                // this.$set(hotspot, "visibleContents", arr)

            }
        },
        handleError(event) {
            event.target.src = this.defaultImage;
        },
        getTextCode(link) {
            const QRCode = require('qrcode');
            var text = link;
            var qrText = "[抖音app]扫码查看";
            let url = ""
            QRCode.toCanvas(document.createElement('canvas'), text, { errorCorrectionLevel: 'H' }, (err, canvas) => {
                if (err) {
                    console.error(err);
                    return;
                }
                const qrWidth = canvas.width;
                const qrHeight = canvas.height;
                const newCanvas = document.getElementById('qrcodeCanvas');
                newCanvas.width = qrWidth;
                newCanvas.height = qrHeight + 30; // 额外的空间用于文字和间隔
                const newCtx = newCanvas.getContext('2d');
                newCtx.fillStyle = 'white';
                newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
                newCtx.drawImage(canvas, 0, 0);
                newCtx.font = '14px Arial';
                newCtx.fillStyle = 'grey';
                const textWidth = newCtx.measureText(qrText).width;
                const canvasWidth = newCanvas.width;
                const textX = (canvasWidth - textWidth) / 2;
                const textY = qrHeight + 10;
                newCtx.fillText(qrText, textX, textY);
                // 将 canvas 的内容转换为 Data URL
                const dataURL = newCanvas.toDataURL('image/png');
                // this.url = dataURL
                url = dataURL

            });
            return url
        }


    }
}
</script>
<style lang="scss" scoped>
.rank_content {
    width: 100%;
    background-color: #ffffff;
    padding: 0 24px;
    box-sizing: border-box;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        border-bottom: 1px solid #F2F2F4;

        .rank_header {
            display: flex;
            align-items: center;      

            .rank_item {
                padding: 20px 0;
                margin-right: 20px;
                color: #25263299;
                border-bottom: 2px solid transparent;
                cursor: pointer;
            }

            .active_rank_item {
                color: #252632;
                border-bottom: 2px solid #685EF5;
            }
        }

        .select_time {
            .time-box {
                display: flex;
                align-items: center;

                .day-type {
                    margin-right: 6px;
                }
            }

            .sort-box {
                margin-top: 16px;
                display: flex;
                align-items: center;

            }

            .time-box,
            .sort-box {

                .time-label,
                .sort-label {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    color: #252632;
                    line-height: 16px;
                }

                gap: 10px;

                .radio-group {
                    padding: 4px;
                    border-radius: 4px;
                    background-color: #f2f2f4;

                    ::v-deep .el-radio-button__orig-radio+.el-radio-button__inner {
                        background-color: transparent;
                        color: rgba(37, 38, 50, 0.6);
                        font-size: 14px;
                        font-weight: 400;
                        border-color: transparent;
                        box-shadow: none;
                        border-radius: 4px;
                        padding: 2px 24px;
                    }

                    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                        background-color: #ffffff;
                        color: #594FEE;
                    }

                    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover {
                        color: #594FEE;
                    }

                    ::v-deep .el-radio-button__inner:hover {
                        color: #2338e6;
                    }
                }

                .time-filter-box {
                    ::v-deep .el-input__inner {
                        background-color: #f2f2f4;
                        color: #252632;
                        border: none;
                        padding: 6px 10px 6px 16px;
                    }

                    ::v-deep .el-select .el-input .el-select__caret {
                        color: #252632;
                    }

                }
            }
        }
    }

    .medio-contain {
        height: calc(100vh - 250px);
        // background-color: #a3a3c4;
        box-sizing: border-box;
        // padding: 0 24px;

        ::v-deep .el-scrollbar .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .medio-list {
            width: 100%;
            height: 100%;
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // gap: 16px;
            // box-sizing: border-box;


        }

        .medio-load {
            display: flex;
            justify-content: center;
            padding: 25px 0;

            span {
                font-weight: 400;
                font-size: 16px;
                color: #888888;
                line-height: 19px;
                cursor: pointer;
            }
        }
    }
}

.ent_name {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px
    }

    .ent_info {

        .areas {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 最多显示两行 */
            overflow: hidden;
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
            line-height: 1.5;
            /* 行高，根据需要调整 */
            max-height: 3em;
            /* 高度根据行高和行数来调整 */
        }
    }
}

::v-deep .el-table__expand-icon {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    /* 上边透明 */
    border-bottom: 5px solid transparent;
    /* 下边透明 */
    border-left: 5px solid #25263299;
    /* 右边是实心 */
}

::v-deep .el-icon-arrow-right:before {
    content: "";
    display: inline-block;
}

// ::v-deep .el-table .el-table__cell{
//     padding: 0;
// }
::v-deep .el-table__expanded-cell[class*=cell] {
    padding: 0;
}

::v-deep .el-input__inner {
    width: 120px;

}

.show_video {
    display: flex;
    width: 100%;
    height: 225px;
    padding: 16px;
    box-sizing: border-box;
    background-color: #f2f2f2;

    .PreVideo {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c4c4c4;
        cursor: pointer;
    }

    .video_content {
        flex: 1;
        width: 0;
        flex-shrink: 0;

        overflow: hidden;
        position: relative;

        .content {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            display: flex;

            transition: transform 0.5s ease;

            .video_item {
                width: 110px;
                margin: 0 20px;
                cursor: pointer;
                flex-shrink: 0;

                .image {
                    width: 100%;
                    height: 147px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }



                .video_title {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.5;
                    /* 或根据实际需要调整 */
                    max-height: 3em;
                    /* 或 line-height * 2 */
                }

            }
        }



    }

    .NextVideo {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c4c4c4;
        cursor: pointer;
    }

}

::v-deep .el-table tr {
    cursor: pointer;

}
</style>
<style>
.row-expand-cover {
    .el-table__expand-icon {
        display: none !important;
    }
}
</style>